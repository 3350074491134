<template>
  <div class="pricingDeposit">
     <van-nav-bar
       title="押金"
       left-arrow
       fixed
       safe-area-inset-top
       placeholder
       @click-left="leftClick"
       @click-right="rightClick">
       <span slot="right">订单押金</span>
       <van-icon name="arrow" slot="right" />
       </van-nav-bar>
    <van-pull-refresh v-model="refreshing"  success-text="刷新成功" @refresh="onRefresh">

        <div class="top">
           <div class="topBox" v-if="hchartShow">
             <!-- 圆形图 -->
            <hcharts  :infoData="infoData"  />
             <!-- 押金占比区域 -->
             <div class="ProportionOfDeposit">
                <div class="flex">
                        <div class="iconLeft" ></div>
                    <div >
                        <div class="text flex_between"> <span>占用押金{{occupyProportion.toFixed(2)}}%</span>  <span style="color:#242424">¥{{ parseInt(infoData.occupy)}}</span> </div>
                        <el-progress :percentage="occupyProportion" :format="format" color="#fb7d7e"></el-progress>
                    </div>
                </div>
                <div class="flex">
                        <div class="iconRight"></div>
                    <div>
                        <div class="text flex_between"><span>可退押金{{refundProportion}}%</span>  <span style="color:#242424">¥{{ parseInt(infoData.refund) }}</span></div>
                        <el-progress :percentage="refundProportion" :format="format" color="#fb7d7e" ></el-progress>
                    </div>
                </div>
             </div>
            <div class="depostTion" v-html="depositDescription"></div>
           </div>

        </div>

        <!-- tap 标签页 -->
        <div class="tap">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >

                    <div class="bdRadius">
                            <h1 class="mingXi">明细</h1>
                           <div class="cards" v-for="(item) in depositlist" :key="item.id">
                            <div class="card ">
                                <div class="text">
                                    <div :class="item.type == 'DEPOSIT' || item.type == 'VIOLATE' || item.type == 'FIRSTRECOMMENREWARD' || item.type == 'FIRSTORDERREWARD' || item.type == 'STORAGEFEEREMIT' || item.type== 'SELL' || item.type== 'PRE_MORTGAGE' || item.type== 'MORTGAGE' || item.type== 'MATERIAL' || item.type == 'INTEGRAL_CHANGE'?'deposit_':'tuimoney_'">
                                      {{item.type == 'DEPOSIT'?'交押金':item.type == 'VIOLATE'?'结算':item.type == 'FIRSTRECOMMENREWARD'?'首次推荐奖励':item.type == 'FIRSTORDERREWARD'?'首次下单奖励':item.type == 'STORAGEFEEREMIT'?'保管费免除':item.type == 'SELL'?'卖料收款':item.type == 'PRE_MORTGAGE'?'存料预收款':item.type == 'MORTGAGE'?'存料尾款':item.type == 'MATERIAL'?'提料尾款':item.type == 'BUY'?'买料付款':item.type == 'PRE_MATERIAL'?'提料预付款':item.type=='INTEGRAL_CHANGE'? '积分兑换' :'退押金'}}</div>
                                </div>
                                <!-- <div class="money">{{item.type == 'DEPOSIT'?'+':'-'}}{{item.money}}¥</div> -->
                                <div class="money">{{item.money>=0?item.type == 'DEPOSIT' ||item.type == 'VIOLATE' || item.type == 'FIRSTRECOMMENREWARD' || item.type == 'FIRSTORDERREWARD' || item.type == 'STORAGEFEEREMIT' || item.type== 'SELL' || item.type== 'PRE_MORTGAGE' || item.type== 'MORTGAGE' || item.type== 'MATERIAL' || item.type == 'INTEGRAL_CHANGE'?'+':'-':''}}{{item.money}}¥</div>
                                <!-- <div class="money">{{item.money?'':'+'}}{{item.money}}¥</div> -->
                            </div>
                            <div class="card borBtm">
                                <div class="text textDate">{{item.created | formatDate_ }}</div>
                                <div class="money orderStatus" :style="{color:item.status =='PROCESS'?'#FF2D2E':'#999999'}">{{item.status == "INEFFECTIVE"?'失效':item.status == "EFFECTIVE"?'有效':'审核中' }}</div>
                            </div>
                         </div>
                    </div>

                </van-list>

        </div>

    </van-pull-refresh>

    <footer class="foot">
        <div class="leftFoot" @click="openPop('refund')">退押金</div>
        <div class="rightFoot" @click="Deposit">交押金</div>
    </footer>

        <!-- 弹出层 -->
        <popup ref="popup" :popShow.sync="popShow" :isRefund.sync="isRefund" :userInfo="userInfo" :depositlist="depositlist"/>

  </div>
</template>

<script>
import * as api from "@/utils/api";
import popup from './components/popup.vue'
import hcharts from './hcharts.vue'
import Stomp from "stompjs";
export default {
    components:{
        popup,
        hcharts
    },
    data() {
        return {
            currentRate: 0,
            // 用户数据
            userInfo:{},
            // 押金详情数据
            depositlist: [],
            // 交押金数据
            deposit:[],
            // 退押金数据
            defund:[],
            // list组件用来标记本次是否正在加载的状态
            loading: false,
            // list组件用来控制是否加载完成的变量
            finished: false,
            // 下拉加载状态
            refreshing: false,
            // tap标签在标签指定 name 属性的情况下，v-model 的值为当前标签的 name
            activeName: 'a',
            // 押金详情数据
            depositData:{},
            // 当前卖出+存料定价单部分的详情数据
            sellData:{},
            buyData:{},
            // 控制弹出层的隐藏显示
            popShow:false,
            // 用来区分是否是退押金的点击事件
            isRefund:false,
            infoData:{
                balance:0, //余额
                refund:0,//可退金额
                occupy:0, // 占用押金
            },
            refundProportion:0,//可退金额占比
            occupyProportion:0,//占用押金占比
            hchartShow:false,//数据加载后渲染子组件
            depositDescription:'',
          // 是否开盘
          trade_: false,
        }
    },
    computed: {
    text() {
        // depositData.total 用户总押金
      return this.depositData.total+'¥' ;
        },
    },
    methods:{

        // 交押金事件
        Deposit(){
            this.$router.push({
                name:'deposit'
            })
            console.log('111');
        },
        linkOccupy(){
            this.$router.push({name:'occupyDeposit'})
        },
        format(percentage) {
                return percentage === 100 ? '' : ``;
        },
        // 交押金/退押金按钮点击事件
        openPop(depositlist){
            console.log(depositlist)

            // 判断点击的按钮
            // 点击的是退押金按钮
            if (depositlist == 'refund') {
          // 调用子组件的方法 获取用户账户信息
                if(this.trade_ && 9 < new Date().getHours() && new Date().getHours()<17){
                  this.$refs.popup.getAuthUser()
                  this.isRefund = true
                }else{
                  return this.$toast('开放时间为周一至周五10:00-17:00'),!1
                }


            }else{
                this.$refs.popup.getBanklist()
                this.isRefund = false

            }
            // 打开弹出层
            this.popShow = true

        },
        onLoad(){
            setTimeout(async()=>{

                // 发请求获取数据

               let depositAll = await api.depositAll()
                depositAll = depositAll.reverse()
                // 全部押金记录数据
               this.depositlist.push(...depositAll)
                // 交押金记录数据
               this.deposit =   depositAll.filter(item =>{
                    return item.type == "DEPOSIT"
                })
                this.defund =  depositAll.filter(item =>{
                    return item.type == "REFUND"
                })

             // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
             // 这样才可以继续下次加载

                this.loading = false
                this.finished = true
            },2000)
        },
        // 下拉事件
     async   onRefresh() {
                let depositAll = await api.depositAll()
                // 数组颠倒
                depositAll = depositAll.reverse()
                // 赋值给 depositlist
                this.depositlist = depositAll
                console.log(this.depositlist);
                this.getUserInfo()
                // 重新加载数据
                this.refreshing = false;
        },
        // 顶部左侧图标点击事件
        leftClick(){
            this.$router.push({
                name:'person'
            })
        },
        // 顶部右侧押金调整事件
        rightClick(){
          this.$router.push({
            name:'breakContract',
            query: {
              path: "pricingDeposit"
            }
          })
        },
        // 封装获取用户余额信息方法
async   getUserInfo(){
            let res = await api.getuser()
               //    获取押金数据
                // 将押金的数据赋值depositData
                    this.userInfo = res
                    this.depositData =  res.depositDetail
                    this.sellData = this.depositData.curSell
                    this.buyData =  this.depositData.curBuy
                    this.infoData.balance =  this.userInfo.earnestMoney || 0
                    this.infoData.refund = this.userInfo.freeEarnestMoney || 0
                    this.infoData.occupy = this.infoData.balance -this.infoData.refund  || 0
                    // this.infoData.balance =  undefined || 0
                    // this.infoData.refund = undefined || 0
                    // this.infoData.occupy = undefined || 0
                    this.occupyProportion = 100 - (Math.round(this.infoData.refund/this.infoData.balance*1000))/10 || 0
                    this.refundProportion = (Math.round(this.infoData.refund/this.infoData.balance*1000))/10 || 0
                    this.hchartShow = true
                    // 动态显示押金
                    this.depositDescription = this.depositDescription ? this.depositDescription.replace(/{#money}/g, this.depositData.curSell.policy*1000) : ''
        },
      connect() {
        this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
        // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
          login: "username",
          passcode: "password"
        };
        this.client.connect(headers, this.onConnected, this.onFailed);
        this.client.debug = null;
      },
      onConnected() {
        const topic = "/price/all";
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
      },
      onFailed(frame) {
        console.log("错误: " + frame);
        if (!this.client.connected) {
          setTimeout(() => {
            this.connect();
          }, 1000);
        }
      },
      responseCallback (frame) {
        this.allpro = []
        const resbody = eval('['+this.unzip(frame.body)+']')[0]
        // const parse_res = JSON.parse(JSON.stringify(resbody));
        this.trade_ = resbody.trade;
        // this.postTime = resbody.time
        // if(this.firstloadData){
        //   this.initDatalist = resbody
        //   this.firstloadData = false
        //   for(const i in this.initDatalist){
        //     if(this.initDatalist[i]['prices'] != undefined){
        //
        //       this.allpro.push(this.initDatalist[i]['prices'])
        //     }
        //   }
        // }else{
        //   this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
        //   for(const i in this.initDatalist){
        //     if(this.initDatalist[i]['prices'] != undefined){
        //       this.allpro.push(this.initDatalist[i]['prices'])
        //     }
        //   }
        // }
      },
      unzip(b64Data) {
        let strData = atob(b64Data);
        const charData = strData.split("").map(function(x) {
          return x.charCodeAt(0);
        });
        const binData = new Uint8Array(charData);
        const data = pako.inflate(binData);
        strData = String.fromCharCode.apply(null, new Uint16Array(data));
        return decodeURIComponent(escape(strData));
      },
    },
    created(){
    this.$nextTick(()=>{
        this.getUserInfo()
    })
    },
    async mounted() {
     let depositDescription = await  api.getDefaultExplain({title:'depositDescription'})
     this.depositDescription = depositDescription[0].content
        console.log('depositDescription',depositDescription);
     this.connect()
    },
}
</script>

<style lang="scss"  scoped>
::v-deep{
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
      font-size:30px !important;
    }


}
.pricingDeposit{
    height: 100%;
    background-color: #f5f5f5;
    .top{
        padding: 30px 40px 0px;
        .topBox{
            background-color: #fff;
        }
        .ProportionOfDeposit{
            width: 100%;
            height: 100px;
            position: relative;
            top: -90px;
            display: flex;
            padding: 0 20px;
            .proTop{
                padding: 0 10px;
                width: 100%;
                display: flex;
                span{
                    font-size: 20px;
                    color:#686868;
                }
                .leftText{
                    flex: 1;
                    font-size: 12px;
                    color: #cacaca;
                    text-align: left;
                }
                .rightText{
                    font-size: 12px;
                    color: #cacaca;
                    text-align: right;
                }
            }
            .oneBg{
                width: 100%;
                height: 15px;
                padding: 0 10px;
                display: flex;
                .oneLeftBg{
                    width: 50%;
                    height: 100%;
                    background:url("~@/assets/images/point to1.png") 98%;
                }
                .oneRightBg{
                    width: 50%;
                    height: 100%;
                    background:url("~@/assets/images/point to4.png") 3%;
                }
            }
            .probtm{
                margin-top: 20px;
            }
            .twoBg{
                width: 100%;
                height: 15px;
                padding: 0 10px;
                display: flex;
                .twoLeftBg{
                    width: 50%;
                    height: 100%;
                    background:url("~@/assets/images/point to2 .png") 97%;
                }
                .twoRightBg{
                    width: 50%;
                    height: 100%;
                    background:url("~@/assets/images/point to3.png") 3%;
                }
            }
            .yjTitle{
                width: 100%;
                position: relative;
                top: 40px;
                padding: 30px 0 30px 25px;
                h3{
                    margin-bottom: 8px;
                }
                p{
                    width: 100%;
                    color: #999999;
                    font-size: 12px;
                    margin-bottom: 3px;
                }
            }
        }
    }
    .tap{
        background-color:#f5f5f5 ;
        position: relative;
        top: -80px;
        padding: 20px 30px;
        margin-bottom: 20px;
    ::v-deep{
        .van-tabs--line{
        .van-tabs__wrap{
            height: 80px;
        .van-tab--active {
            font-size: 28px;
            font-weight: 600;
            font-family: PingFang SC-Medium, PingFang SC;
            }
        .van-tab{
            font-size: 26px;
            font-family: PingFang SC-Medium, PingFang SC;
            line-height: 28px;
        }
         }
        }
    }
    .bdRadius{
            border-radius: 8px;
    }


    .cards{
            width: 98%;
            height: 160px;
            margin: 0px auto 0px;
            background-color: #fff;
            padding: 20px 20px 0;
           .borBtm{
                border-bottom: 1px solid #dfdfdf;
                padding: 0  0 76px !important;

            }
            &:last-child .borBtm{
                border: none;
            }
            .card{
                display: flex;
                height: 60px;
                line-height: 54px;
                .text{
                    width: 50%;
                    text-align: left;
                    padding-top: 6px;
                }
                .money{
                    width: 50%;
                    text-align: right;
                    font-size: 36px;
                }
                .orderStatus,.textDate{
                    font-size: 28px;
                    color:#999999;
                }
            }
    }
     }
    .foot{
        height: 84px;
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        .leftFoot,.rightFoot{
            width: 50%;
            text-align: center;
            background-color: #5473FF;
            line-height: 84px;
            font-size: 34px;
            color: #fff;
        }
        .rightFoot{
            background-color: #FF2D2E;
        }
    }
 ::v-deep{
    .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        .van-nav-bar__title{
            font-size: 40px;
        }
        .van-icon{
            font-size: 40px;
            color: #1e1e1e;
        }
        .van-nav-bar__right{
          font-size: 28px;
          .van-icon{
            font-size: 40px;
            color: #1e1e1e;
          }
        }
        }

    }
    .van-circle{
        margin: 0px auto;
        display: block;
        transform: rotate(180deg);
    }
    .two svg {
        transform: rotate(180deg);
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
}
    .two  .van-circle__text{
        position: absolute;
        top: 28%;
        left: -9px;
        color: #F92424;
        font-size: 22px;
        font-weight: 600;
        }
    .one .van-circle__hover {
    fill: none;
     stroke: url(#van-circle-gradient-0);
    stroke-linecap: round;
}
    .two .van-circle__hover {
    fill: none;
     stroke: url(#van-circle-gradient-1);
    stroke-linecap: round;
}
    .van-hairline--bottom:after {
        border-bottom-width: 0px;
    }
    .el-progress.el-progress--line{
        width: 250px;
        .el-progress-bar{
            padding: 0 !important;
        }
    }
    .van-icon{
        margin-right: 12px;
        line-height: 60px;
    }
 }
}
.iconLeft{
    width: 40px;
    height: 40px;
    margin-top: 14px;
    margin-right: 14px;
    background: url('~@/assets/images/icon_deposit_used@2x.png');
    background-size: 100%;
}
.iconRight{
    width: 40px;
    height: 40px;
    margin-top: 14px;
    margin-right: 14px;
    background: url('~@/assets/images/icon_deposit_refundable@2x.png');
    background-size: 100%;
}
.flex{
    display: flex;
    height: 100%;
    width: 340px;
    .text{
        font-size: 20px;
        color: #999999;
    }
}
.flex_between{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.deposit_{
    background-color: #FF2D2E;
    color: #fff;
    width: 200px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 50px;
    font-size: 28px;
}
.tuimoney_{
    background-color: #5473FF;
    color: #fff;
    width: 200px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 50px;
    font-size: 28px;
}
.deposit_color{
    font-size: 30px;
    color: #f92424;
}
.tuimoney_color{
    font-size: 30px;
    color: #ffc38b;
}
.shixiao{
    font-size: 30px;
    color: #e2e2e2;
}
.mingXi{
    font-size: 32px;
    font-weight: 500;
    color: #242424;
    padding: 0 4px;
    margin-bottom: 10px;
}
.depostTion{
    position: relative;
    top: -110px;
    padding: 0 24px;
    color: #999999;
    font-size: 20px;
}
</style>
